@import './styles/variables';

@font-face {
  font-family: regular;
  src: url('./assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: medium;
  src: url('./assets/fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: ligth;
  src: url('./assets/fonts/Inter-Light.ttf');
}

@font-face {
  font-family: semibold;
  src: url('./assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: atkinson;
  src: url('./assets/fonts/AtkinsonHyperlegible-Regular.ttf');
}

* {
  font-family: regular;
  letter-spacing: .03rem;
}

.ant-notification-notice-message,
.ant-notification-notice-description,
.ant-notification-notice-close {
  color: #fff !important
}

.ant-message-notice-content {
  max-width: 500px;
}

.ant-table-thead {
  th,
  td {
    background-color: $primary-color !important;
    border: 1px solid $primary-color-hover !important;
    border-top: none !important;
    border-bottom: none !important;
    color: #fff !important;
    white-space: nowrap !important;
  }
}

.ant-table-content {
  border-radius: 10px !important;
}

.nowrap {
  white-space: nowrap;
}